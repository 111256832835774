body {
	margin: 0px;
	padding: 0px;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	line-height: 1.5em;
	background-color: $body;
	color: $body-txtColor;
	-webkit-font-smoothing: auto;
	-webkit-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	user-select: none;
	font-weight: 500;
}

// .background-image {
// 	// background-image: url(../images/backgrounds-football-2.jpg);
// 	background-size: cover;
// 	background-color: $body-bg;
// }

// .main-transparenrbg {
// 	filter: alpha(opacity=80);
// 	-moz-opacity: 0.8;
// 	-khtml-opacity: 0.8;
// 	opacity: 0.8;
// }

ul,
ol {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
	}
}

a,
a:hover {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.text-justify {
	text-align: justify !important;
}

.v-hidden {
	visibility: hidden !important;
}

.d-none {
	display: none !important;
}

.d-block {
	display: block !important;
}

.cursor-pointer {
	cursor: pointer;
}

.truncate {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.space-5 {
	margin: 5px auto;
	padding: 2px 0;
}

.space-10 {
	margin: 10px auto;
	padding: 5px 0;
}

.p-0 {
	padding: 0 !important;
}

.m-0 {
	margin: 0 !important;
}

// .p-b{
// 	padding-bottom: 0px !important;
// }
.mt-10 {
	margin-top: 5px;
}

.mt-20 {
	margin-top: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mr-10 {
	margin-right: 10px;
}

.w-100 {
	width: 100% !important;
}

.w-70 {
	width: 70px;
}

.w-65 {
	width: 65px;
}

.width-event {
	width: 43px;
}

.w-80 {
	width: 80px;
}

.w-30 {
	width: 30px;
}

.w-50 {
	width: 50px;
}

.w-75 {
	width: 75px;
}

.w-100px {
	width: 100px;

	@media screen and (max-width: $tab_Device) {
		width: 80px;
	}
}

.w-110 {
	width: 110px;

}

.w-130 {
	width: 130px;
}

.w-150 {
	width: 150px;

	@media screen and (max-width: $tab_Device) {
		width: 75px;
	}
}

.w-170 {
	width: 220px !important;
}

// home page alignments
.w-date {
	width: 175px;
	padding-left: 45px !important;

	@media screen and (max-width: $medium_Device) {
		min-width: 135px;
		padding-left: 10px !important;
	}

	@media screen and (max-width: $tab_Device) {
		min-width: 135px;
		padding-left: 0px !important;
	}
}

.w-koTime {
	width: 65px;
}

.w-form {
	width: 178px;

	@media screen and (max-width: $medium_Device) {
		width: 130px;
	}

	@media screen and (max-width: $tab_Device) {
		width: 115px;
	}
}

.w-fixture {
	width: 370px;

	@media screen and (max-width: $medium_Device) {
		width: auto;
	}

	@media screen and (max-width: $tab_Device) {
		width: auto;
	}
}

.w-bestBet {
	width: 120px;

	@media screen and (max-width: $medium_Device) {
		width: 100px;
	}

	@media screen and (max-width: $tab_Device) {
		width: 90px;
	}
}

.w-winPredictor {
	width: 210px;

	@media screen and (max-width: $medium_Device) {
		width: auto;
	}

	@media screen and (max-width: $tab_Device) {
		width: auto;
	}
}

// .home-away-teams {
// 	width: 175px;
// }
.pt-0 {
	padding-top: 0px !important;
}

/*common for all tables */
.rank {
	width: 100px;
}

.team {
	width: 150px;
	text-align: left !important;

	.groupStagelogo {
		margin-right: 5px;
	}

	img {
		width: 20px;
	}

	@media screen and (max-width: $tab_Device) {
		width: 110px;
	}
}

.teamSpacing {
	padding-left: 50px !important;
}

/*for form */
.form {
	width: 250px;

	@media screen and (max-width: $tab_Device) {
		width: auto;
		;
	}
}

/*first team to score */
.p {
	width: 315px;

	@media screen and (max-width: $tab_Device) {
		width: auto;
		;
	}
}

/*handicap */
.handicap-p {
	width: 120px;

	@media screen and (max-width: $tab_Device) {
		width: auto;
		;
	}
}

.fullLosses {
	width: 100px;
}

/*odd/even */
.even {
	width: 100px;
}

*[data-href] {
	cursor: pointer;
}

.playerHT-FT {
	width: 80px;
}

//   .teamPointer{
// 	cursor: pointer;
//   }
//   .teamDefault{
// 	cursor:default;
//   }

.dbsvgbutton {
	background-color: transparent;
	border: none;
	width: auto;
}

.dbSvg {
    height: 24px;
    width: 40px;
    border-radius: 4px;
}
.dbsvgbutton {
    padding: 1px 0px;
	margin-right: 6px;
}

.HKJC_logo{
	min-width: 100px;
}